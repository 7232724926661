import { Router } from 'vue-router'
import { logger } from '@/services/logger/loggers'

export const registerMiddlewares = (router: Router) => {
  router.beforeEach(async (to, from) => {
    const middlewares = to.meta.middlewares

    if (!middlewares || middlewares.length === 0) {
      return true
    }

    logger.debug(
      `[orcas-mobile-shell - middlewares]: Executing ${middlewares.length} middlewares for route ${to.fullPath}`,
      {}
    )

    for (const middleware of middlewares) {
      const result = await middleware({ to, from })
      if (
        typeof result === 'object' ||
        typeof result === 'string' ||
        result === false
      ) {
        return result
      }
    }

    logger.debug(
      `[orcas-mobile-shell - middlewares]: Executed middlewares for route ${to.fullPath}`,
      {}
    )

    return true
  })
}
