import { isLocalEnv } from '@/common/config'
import { logger } from '@/services/logger/loggers'
import { sendErrorInMonitor } from '@/services/monitor'

export function executeSafelyWithLogging<T extends (...args: any[]) => any>(
  callback: T,
  callbackName: string
) {
  try {
    callback()
  } catch (error) {
    logger.error(
      `[orcas-mobile-shell] Error while executing ${callbackName}: ${error}`,
      { error }
    )
  }
}

export default (error: any, message = 'Unhandled shell error') => {
  if (error.notification) {
    console.log(error.notification, 'error', {})
  }
  if (isLocalEnv()) {
    console.log(
      `Check the console log for details. This message won't be displayed in production. ${message}`,
      'error',
      {}
    )
    console.error(message, error)
  } else {
    sendErrorInMonitor(error, { message })
  }
}
