import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'
import { AnalyticsProvider } from '@sennder/shell-utilities'

import { convertToKebabCase } from '@/modules/utils'
import { logger } from '@/services/logger/loggers'

export const DEFAULT_EVENT_SOURCE = 'orcas_mobile'

const getPageViewEventName = (name: string): string => {
  if (!name) {
    throw Error(
      '[orcas-mobile-shell - Analytics]: Could not track invalid page name'
    )
  }
  // custom formatter because prefix !== module
  return `orcas/view/${convertToKebabCase(name)}-page`
}

const getTrackEventName = (name: string): string => {
  // TODO: We need to check with all teams to use the latest pattern naming
  const isOrcasEvent = name.startsWith('orcas-')
  const isNewTMSEvent = name.startsWith('orcas/')
  return isOrcasEvent || isNewTMSEvent ? name : `tms-${name}`
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  // enforce app specific analytics configuration
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: DEFAULT_EVENT_SOURCE,
      dedupePageEvents: true,
      formatEventName: getTrackEventName,
      formatPageEventName: getPageViewEventName,
    })
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'orcas-mobile-shell',
  submodule: '',
})
