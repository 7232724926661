import { logger } from './services/logger/loggers'

export type Mark =
  | 'load-scripts'
  | 'mount'
  | `mf-${string}`
  | `timedFn(${string})`

const perfSession = `${Math.random().toString(36).substring(2)}`

export function markStart(mark: Mark) {
  performance.mark(`${mark}-start`)
}

export function markEnd(mark: Mark) {
  performance.mark(`${mark}-end`)
  logMark(mark)
}

function logMark(mark: Mark) {
  const measure = performance.measure(mark, `${mark}-start`, `${mark}-end`)

  const msg = `[time] ${mark}: ${measure.duration?.toFixed(2)} ms`

  logger.info(msg, { perfSession })
  console.log(msg)
}

export const timedFn = async <R>(
  name: string,
  fn: () => Promise<R>
): Promise<R> => {
  markStart(`timedFn(${name})`)
  const returnValue = await fn()
  markEnd(`timedFn(${name})`)
  return returnValue
}
