import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-spa-js'
import { logger } from '@/services/logger/loggers'
import { getTenant } from '@/plugins/tenants'
import { AUTH0_MOTHERSHIP_AUDIENCE } from '@/common/config'
import { logout } from '@/store/logoutActions'

interface AccessToken {
  refresh_token: string
  access_token: string
  token_type: string
  expires_in: number
  expires_on: number
  id_token: string
}

interface TokenStore {
  token_request_key?: string
  tokens: { [audience: string]: AccessToken | undefined }
}

let TOKEN_STORE: TokenStore = { tokens: {} }
const TOKENS_LOCALSTORE_KEY = 'CUS_AUTH0_TOKENS'

const processErrorResponseData = async (
  response: Response,
  errorMessage?: string
) => {
  const reason = response.headers
    .get('content-type')
    ?.includes('application/json')
    ? await response.json()
    : await response.text()

  if (reason?.code === 'INVALID_REFRESH_TOKEN_ERROR') {
    logger.info('Refresh token expired.', { reason })
  } else if (reason?.code === 'INVALID_USER_CREDENTIALS') {
    logger.info('Invalid user credentials.', { reason })
  }

  const error = new Error(`${errorMessage} - ${JSON.stringify(reason)}`)

  logger.error('Error while fetching token', { error })
}

const getCommonHeaders = () => {
  if (!process.env.VUE_APP_CARRIER_USER_SERVICE_URL) {
    throw new Error('VUE_APP_CARRIER_USER_SERVICE_URL is not found')
  }

  const tenantHeaderName =
    process.env.VUE_APP_CARRIER_USER_SERVICE_URL.includes('cloud.sennder.com')
      ? 'X-Tenant'
      : 'tenant'
  return {
    'Content-Type': 'application/json',
    [tenantHeaderName]: getTenant(),
  }
}

const createCUSAuthInstance = () => {
  const persistTokens = () => {
    if (TOKEN_STORE.token_request_key) {
      localStorage.setItem(TOKENS_LOCALSTORE_KEY, JSON.stringify(TOKEN_STORE))
    } else {
      localStorage.removeItem(TOKENS_LOCALSTORE_KEY)
    }
  }

  const requestNewToken = async (
    audience: string
  ): Promise<AccessToken | undefined> => {
    const response = await fetch(
      `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/token`,
      {
        method: 'POST',
        body: JSON.stringify({
          audience,
          credentials: TOKEN_STORE.token_request_key,
        }),
        headers: {
          ...getCommonHeaders(),
        },
      }
    )

    if (response.ok) {
      return (await response.json()) as AccessToken
    } else {
      processErrorResponseData(
        response,
        `Unable to fetch token for audience: ${audience}.`
      )

      logger.info(
        `Error while fetching new token. Removing ${TOKENS_LOCALSTORE_KEY} from local storage.`,
        {}
      )
      localStorage.removeItem(TOKENS_LOCALSTORE_KEY)

      throw new Error('Unable to fetch token.')
    }
  }

  const requestNewTokenWithRefresh = async (
    refreshToken: string
  ): Promise<AccessToken | undefined> => {
    const response = await fetch(
      `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/token/refresh`,
      {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
        headers: {
          ...getCommonHeaders(),
        },
      }
    )
    if (response.ok) {
      return (await response.json()) as AccessToken
    } else {
      processErrorResponseData(response, 'Unable to fetch new token.')
      logger.info(
        'Error while fetching new token with refresh token. Logging out...',
        {}
      )
      await logout()
    }
  }

  const revokeTokens = async () => {
    for (const [audience, tokenObject] of Object.entries(TOKEN_STORE.tokens)) {
      logger.info(`Revoking token for ${audience}...`, {})
      if (tokenObject && tokenObject.refresh_token) {
        await fetch(
          `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/logout`,
          {
            method: 'POST',
            body: JSON.stringify({
              refresh_tokens: [tokenObject.refresh_token],
            }),
            headers: {
              ...getCommonHeaders(),
            },
          }
        )
      }
    }

    TOKEN_STORE = { tokens: {} }
    persistTokens()
  }

  const restoreSession = () => {
    const existingTokens = localStorage.getItem(TOKENS_LOCALSTORE_KEY)
    if (existingTokens) {
      const parsedExistingTokens = JSON.parse(existingTokens)
      if (parsedExistingTokens.token_request_key) {
        TOKEN_STORE = { ...parsedExistingTokens }
        if (!TOKEN_STORE.tokens) {
          TOKEN_STORE.tokens = {}
        }
      }
    }
  }

  restoreSession()

  return {
    async getToken(options?: GetTokenSilentlyOptions): Promise<string | null> {
      if (!this.hasTokenRequestKey()) {
        throw new Error('Unauthenticated')
      }

      if (!AUTH0_MOTHERSHIP_AUDIENCE) {
        throw new Error('AUTH0_MOTHERSHIP_AUDIENCE not found')
      }

      let audience = options?.audience
      if (!audience) {
        audience = AUTH0_MOTHERSHIP_AUDIENCE
      }

      let tokenObject = TOKEN_STORE.tokens[audience]

      if (!tokenObject) {
        tokenObject = await requestNewToken(audience)
      } else if (
        tokenObject.refresh_token &&
        tokenObject.expires_on - new Date().getTime() < 10000
      ) {
        tokenObject = await requestNewTokenWithRefresh(
          tokenObject.refresh_token
        )
      }

      if (tokenObject) {
        TOKEN_STORE.tokens[audience] = tokenObject
        persistTokens()
      }

      return tokenObject?.access_token || null
    },
    async logout(options?: LogoutOptions): Promise<void> {
      await revokeTokens()
      if (options?.returnTo) {
        window.location.href = options.returnTo
      }
    },
    async hasTokenRequestKey(): Promise<boolean> {
      return !!TOKEN_STORE.token_request_key
    },
    async hasTokens(): Promise<boolean> {
      return !!Object.keys(TOKEN_STORE.tokens).length
    },
    async setTokenRequestKey(requestKey: string): Promise<void> {
      TOKEN_STORE = {
        token_request_key: requestKey,
        tokens: {},
      }
      persistTokens()
    },
  }
}

export const cusAuthInstance = createCUSAuthInstance()
