import { i18n } from './services/i18n'
import router from './router'
import { cusAuthInstance } from './services/cusAuth'
import errorsHandler from '@/services/errors-handler'
import { logger } from '@/services/logger/loggers'
import { hasBeenAuthenticated } from './services/tokenManager'
import { loadUserData } from './store'
import { logout } from './store/logoutActions'
import { mountApp } from './bootstrap'
import { markEnd, markStart, timedFn } from './performance'
import { translationProvider } from '@/services/translationProvider'

interface IMobileShellHandler {
  eventName: string
  payload?: Record<string, any>
}

const logoutUser = async () => {
  await logout()
}

const setLanguage = async (payload: Record<string, any>) => {
  if (!payload.language) {
    throw new Error('Invalid payload for setLanguage event')
  }

  let language = payload.language.toString()

  logger.info(
    `[orcas-mobile-shell - setLanguage] Language received from app: ${language}`,
    {}
  )

  if (language.includes('-')) {
    language = language.split('-')[0]
  }

  await translationProvider.set(language)
}

const setPage = async (payload: Record<string, any>) => {
  if (!payload.route) {
    throw new Error('Invalid payload for setPage event')
  }
  logger.info(
    `[orcas-mobile-shell - setPage] Page changed from app: ${payload.route}`,
    {}
  )
  await router.push(payload.route)
}

let processingTokenRequest = false

const setTokenRequest = async (payload: Record<string, any>) => {
  let loginFailed = false
  if (!payload.token) {
    throw new Error(
      '[orcas-mobile-shell - setTokenRequest]: Invalid payload for setToken event'
    )
  }
  logger.debug(
    `[orcas-mobile-shell - setTokenRequest]: Payload received ${JSON.stringify({
      ...payload,
      token: `${payload.token.slice(0, 5)}...`,
    })}`,
    {}
  )

  if (processingTokenRequest) {
    logger.debug(
      `[orcas-mobile-shell - setTokenRequest]: Already processing request, ignoring`,
      {}
    )
    return
  }

  try {
    processingTokenRequest = true

    const isLogin = !(await timedFn('hasBeenAuthenticated', () =>
      hasBeenAuthenticated()
    ))
    if (isLogin) {
      cusAuthInstance.setTokenRequestKey(payload.token)
      logger.info(
        '[orcas-mobile-shell - setTokenRequest]: Token received from app',
        {}
      )
    } else {
      logger.info(
        '[orcas-mobile-shell - setTokenRequest]: Already authenticated',
        {}
      )
    }
    const loaded = await timedFn('loadUserData', () => loadUserData(isLogin))
    if (loaded) {
      notifyShellLoaded()
    }
  } catch (error) {
    loginFailed = true
    errorsHandler(
      error,
      '[orcas-mobile-shell - setTokenRequest]: Error processing token key from app'
    )
    notifyAuthError(error)
  } finally {
    markStart('mount')

    // mount application after user data is loaded
    mountApp()

    processingTokenRequest = false

    if (loginFailed) {
      await router.push('/not-authorized')
    } else {
      // handle route rediretion
      const routePath = payload.route || '/'
      if (routePath !== '/' && routePath === router.currentRoute.value.path) {
        // If the route is the same, we don't need to do anything
        logger.debug(
          `[orcas-mobile-shell - setTokenRequest]: Already in ${routePath}`,
          {}
        )
      } else {
        logger.info(
          `[orcas-mobile-shell - setTokenRequest]: Redirecting to ${routePath}`,
          {}
        )
        await router.push(routePath)
      }
    }

    markEnd('mount')
  }
}

enum MobileEvents {
  Logout = 'logout',
  SetLanguage = 'setLanguage',
  SetPage = 'setPage',
  SetToken = 'setTokenRequest',
}

const mobileEventHandlers = {
  [MobileEvents.Logout]: logoutUser,
  [MobileEvents.SetLanguage]: setLanguage,
  [MobileEvents.SetPage]: setPage,
  [MobileEvents.SetToken]: setTokenRequest,
}

const mobileShellHandler = async (mobileEvent: IMobileShellHandler) => {
  try {
    logger.debug(
      `Event ${mobileEvent.eventName} received with payload: ${
        mobileEvent.payload ? JSON.stringify(mobileEvent.payload) : ''
      }`,
      {}
    )
    await mobileEventHandlers[mobileEvent.eventName as MobileEvents](
      mobileEvent.payload as any
    )
  } catch (error: any) {
    errorsHandler(
      error,
      `[orcas-mobile-shell - handler event] Error processing event: ${mobileEvent}`
    )
  }
}

export const registerMobileListener = () => {
  window.addEventListener('contextmenu', (event) => event.preventDefault())
  window.addEventListener('mobileShellHandler', (event) => {
    const customEvent = event as CustomEvent<IMobileShellHandler>
    mobileShellHandler(customEvent.detail)
  })
}

interface MobileNotification {
  orderID: string
  type: string
}

const handleMobileCallback = (callback: string, content: any) => {
  try {
    /* eslint-disable */
    // @ts-ignore
    if(window.webkit?.messageHandlers[callback]){
      // @ts-ignore
      window.webkit.messageHandlers[callback].postMessage(JSON.stringify(content))
      logger.info(`[orcas-mobile-shell]: iOS ${callback} callback called`, {content})
    } else{
      // @ts-ignore
      if (window[callback]) {
        // @ts-ignore
        window[callback].postMessage(JSON.stringify(content))
        logger.info(`[orcas-mobile-shell]: Android ${callback} callback called`, {content})
      } else{
        logger.warn(`[orcas-mobile-shell]: No ${callback} callback available`, {})
      }
    }
    /* eslint-disable */
  } catch (error) {
    logger.error(
      `[orcas-mobile-shell - handleMobileCallback]: ${callback} callback not working`,
      { error }
    )
  }
}

const handleMobileAlert = () => {
  const message = i18n.global.t('mobile.feature.unavailable')
  window.alert(message)
}

enum MobileNotificationCallbacks {
  OrderDetails = 'nativeOrderDetailsCallback',
  ShellLoaded = 'nativeShellLoadedCallback',
  AuthError = 'nativeAuthErrorCallback',
}

const mobileNotificationCallback = {
  AcceptNowSucceeded: MobileNotificationCallbacks.OrderDetails,
  AssignmentAccepted: MobileNotificationCallbacks.OrderDetails,
  OfferAccepted: MobileNotificationCallbacks.OrderDetails,
}

type MobileNotificationCallback = keyof typeof mobileNotificationCallback

export const handlePushNotifications = (notification: MobileNotification) => {
  if(mobileNotificationCallback[notification.type as MobileNotificationCallback]){
    handleMobileCallback(mobileNotificationCallback[notification.type as MobileNotificationCallback], notification)
  } else {
    handleMobileAlert()
  }
}

function notifyShellLoaded() {
  handleMobileCallback(MobileNotificationCallbacks.ShellLoaded, {})
}

function notifyAuthError(error: any) {
  handleMobileCallback(MobileNotificationCallbacks.AuthError, error)
}
