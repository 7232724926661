import { createI18n } from 'vue-i18n'
import { App } from 'vue'

export const defaultLanguage = 'en'

const i18n = createI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: defaultLanguage, // set fallback locale
  messages: {},
})

function registerI18nPlugin(app: App) {
  app.use(i18n)
}

export { i18n, registerI18nPlugin }
