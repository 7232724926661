import { loadState } from '@/store'
import { getAuth0Header } from '@/services/tokenManager'
import { extractTenantFromToken, setTenant } from '@/plugins/tenants'
import { logger } from '@/services/logger/loggers'
import { identifyUserInTracking } from '@/services/tracking'

export const loadUserData = async (onLogin = false) => {
  logger.debug('[orcas-mobile-shell - loadUserData]: Analytics initialized', {})

  const authHeader = await getAuth0Header()
  if (!authHeader) {
    console.warn('[orcas-mobile-shell - loadUserData] - Undefined auth header')
    return false
  }

  const tenant = extractTenantFromToken(authHeader)
  if (tenant) {
    setTenant(tenant)
  }

  logger.debug('[orcas-mobile-shell - loadUserData]: Tenant extracted', {})

  const dataLoaded = await loadState()

  logger.debug('[orcas-mobile-shell - loadUserData]: User state loaded', {})

  if (!dataLoaded) {
    return false
  }

  identifyUserInTracking(onLogin)

  return true
}
