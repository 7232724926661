import {
  CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
  CARRIER_PROFILE_PUBLIC_SERVICE_URL,
} from '@/common/config'
import { getAuth0Header } from '@/services/tokenManager'
import {
  Carrier,
  CarrierApi,
  Configuration,
  Contact,
  ContactsApi,
} from '@sennder/carrier-profile-public-axios-client'
import { IUserContact } from '@sennder/senn-node-microfrontend-interfaces'
import carrierUserService from './carrierUserService'
import { ICUSUser } from '@/types/cus'

class CarrierProfilePublicService {
  private async getApiConfig(): Promise<Configuration> {
    const accessToken = await this.getCPPSToken()
    const options = {
      headers: {
        Authorization: accessToken,
      },
    }
    return new Configuration({
      basePath: CARRIER_PROFILE_PUBLIC_SERVICE_URL,
      baseOptions: options,
    })
  }

  async carrierApiInstance(): Promise<CarrierApi> {
    const apiConfig = await this.getApiConfig()
    return new CarrierApi(apiConfig)
  }

  async contactsApiInstance(): Promise<ContactsApi> {
    const apiConfig = await this.getApiConfig()
    return new ContactsApi(apiConfig)
  }

  async getCPPSToken(): Promise<string> {
    const authTokenHeader = await getAuth0Header({
      audience: CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
    })
    if (!authTokenHeader) {
      throw new Error('[CPPS]: Cant build a request. Token is empty')
    }

    return authTokenHeader
  }

  async getCarrier(carrierId: string): Promise<Carrier> {
    try {
      const api = await this.carrierApiInstance()
      const response = await api.readCarrierV1CarriersCarrierIdGet(carrierId)
      if (response.status !== 200) {
        throw new Error()
      }
      return response.data
    } catch (error: any) {
      throw new Error(`Error getting carrier data from CPPS ${error.stack}`)
    }
  }

  async getPrimaryContact(carrierId: string): Promise<Contact | null> {
    try {
      const api = await this.contactsApiInstance()
      const response =
        await api.listCarrierContactsV1CarriersCarrierIdContactsGet(
          carrierId,
          undefined,
          true
        )

      if (response.data.results?.length > 0) {
        const result = response.data.results[0]
        if (result) {
          return response.data.results[0]
        }
      }
    } catch (error: any) {
      const errorMessage = `Error fetching primary contact for carrierId ${carrierId} from CPPS: ${JSON.stringify(
        error.response?.data ?? error.message
      )}`
      throw new Error(errorMessage)
    }
    return null
  }

  async exchangeMothershipCarrierIdForNewUUID(
    mothershipCarrierId: number
  ): Promise<string> {
    try {
      const api = await this.carrierApiInstance()
      const response =
        await api.mothershipIdToCpsIdV1CarriersIdFromMothershipMothershipIdGet(
          mothershipCarrierId
        )

      return response.data.id
    } catch (error: any) {
      throw new Error(
        `Error exchanging carrier mothership id for uuid CPPS ${
          error.response?.data || error.message
        }`
      )
    }
  }

  public async getContactDetails(): Promise<IUserContact> {
    let user: ICUSUser

    try {
      user = await carrierUserService.whoami()

      if (!user.carrier_ids?.[0]) {
        throw new Error('User is not associated with a carrier')
      }
    } catch (error: any) {
      throw new Error('Error fetching CUS user data')
    }

    const carrierId = String(user.carrier_ids[0])

    const carrier = await this.getCarrier(carrierId)
    const mothershipCarrierId = String(carrier.mothership_id) ?? ''

    const contact = await this.getPrimaryContact(carrierId)

    const contactDetails: IUserContact = {
      carrierId: mothershipCarrierId,
      email: contact?.email ?? '',
      firstName: contact?.first_name ?? '',
      lastName: contact?.last_name ?? '',
      fullName: `${contact?.first_name ?? ''} ${contact?.last_name ?? ''}`,
      jobTitle: contact?.role ?? '',
      phone: contact?.phone ?? '',
    }

    return contactDetails
  }
}

export default new CarrierProfilePublicService()
