import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'
import { getAuth0Header } from '@/services/tokenManager'
import { IUserCompany } from '@sennder/senn-node-microfrontend-interfaces'
import { MOTHERSHIP_URL } from '@/common/config'

class MothershipService extends HttpClient {
  public async getCompanyDetails(): Promise<IUserCompany> {
    return this.get('/ma-franchise/queries/company-details/')
  }

  public async changeUserLanguage(language: string): Promise<void> {
    return this.post('/accounts/commands/change-user-language', {
      data: { language: language },
    })
  }
}

if (!MOTHERSHIP_URL) {
  throw new Error('MOTHERSHIP_URL not found')
}

export default new MothershipService({
  httpProvider,
  getAuthHeader: getAuth0Header,
  baseURL: MOTHERSHIP_URL,
})
