import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { hasBeenAuthenticated } from '@/services/tokenManager'
import { DEFAULT_ROUTE } from '@/common/constants'
import { moduleConfiguration } from '@/modules-configuration'

export type MiddlewareOptions = {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}

export type Middleware = (
  options: MiddlewareOptions
) =>
  | RouteLocationRaw
  | boolean
  | string
  | Promise<RouteLocationRaw | boolean | string>

export const isAuthenticated: Middleware = async ({ to }) => {
  const isAuthenticated = !!(await hasBeenAuthenticated())
  if (isAuthenticated) {
    return true
  } else {
    return {
      path: '/login',
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

export const isRootAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await hasBeenAuthenticated())

  if (isAuthenticated) {
    return { path: moduleConfiguration[0].route }
  } else {
    return { name: 'Login' }
  }
}

export const isNotAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await hasBeenAuthenticated())
  if (isAuthenticated) {
    return { path: DEFAULT_ROUTE }
  } else {
    return true
  }
}

export const projectMiddlewares = {
  isAuthenticated,
  isRootAuthenticated,
  isNotAuthenticated,
}
