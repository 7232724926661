import '@/assets/tailwind.global.css'
import '@/assets/scss/reset.scss'
import '@/assets/scss/styles.scss'
import '@sennder/design-system-core'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_MOTHERSHIP_AUDIENCE,
  LD_KEY,
  isLocalEnv,
} from '@/common/config'
import { initAuth0 } from '@sennder/senn-node-auth0-frontend'
import { registerMobileListener } from './mobile-handler'
import registerPlugins from '@/plugins'
import errorsHandler from '@/services/errors-handler'
import { logger } from './services/logger/loggers'
import { registerRoutesAndMiddlewares } from './router/routes'
import { startTracking } from './services/tracking'
import { markEnd, timedFn } from './performance'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { envOverrides } from '@sennder/shell-utilities'
import { translationProvider } from '@/services/translationProvider'
import { loadStyles, themeProvider, Themes } from '@sennder/design-system-core'
async function initialize() {
  window.onerror = function (message, url, line, column, error) {
    logger.error('[orcas-mobile-shell - global error]', { error })
  }
  registerMobileListener()

  if (isLocalEnv()) {
    envOverrides.load()
  }

  logger.info('[orcas-mobile-shell - bootstrap]: Initializing app...', {})

  await timedFn('initAuth0', async () => {
    if (!AUTH0_CLIENT_ID) {
      throw new Error('AUTH0_CLIENT_ID not found')
    }
    if (!AUTH0_DOMAIN) {
      throw new Error('AUTH0_DOMAIN not found')
    }

    await initAuth0({
      client_id: AUTH0_CLIENT_ID,
      domain: AUTH0_DOMAIN,
      audience: AUTH0_MOTHERSHIP_AUDIENCE,
      cacheLocation: 'localstorage',
    })
  })

  logger.debug('[orcas-mobile-shell - bootstrap]: Auth0 initialized', {})

  await Promise.all([
    timedFn('initLaunchDarkly', async () => {
      if (!LD_KEY) {
        throw new Error('LD_KEY not found')
      }

      initLaunchDarkly(LD_KEY, getLaunchDarklyAnonymousContext())
    }),
    timedFn(
      'init translationProvider',
      async () => await translationProvider.initialize()
    ),
  ])

  logger.debug(
    '[orcas-mobile-shell - bootstrap]: LaunchDarkly initialized. Default translations loaded',
    {}
  )
}

let mounted = false

export function mountApp() {
  if (mounted) {
    return
  }

  themeProvider('html', Themes.Light)
  const app = createApp(App)
  registerRoutesAndMiddlewares(router)
  app.use(router)
  registerPlugins(app)
  app.mount('#app')

  mounted = true

  logger.debug('[orcas-mobile-shell - main]: App mounted', {})
}

async function start() {
  markEnd('load-scripts') // Start event is in index.html
  // TODO: if required, check for user consent before starting tracking
  await timedFn<void>('startTracking', async () => startTracking())

  try {
    await timedFn('initialize', async () =>
      Promise.all([loadStyles('body'), initialize()])
    )
  } catch (error) {
    errorsHandler(error, 'Orcas mobile shell init')
  }
}

start()
