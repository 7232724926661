import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import {
  IUser,
  IUserCompany,
} from '@sennder/senn-node-microfrontend-interfaces'

export const identifyUserInLaunchDarkly = async (
  user: IUser,
  company: IUserCompany
) => {
  const ldContext: LDContext = {
    kind: 'user',
    key: user.uuid,
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.timezone,
    organisationType: 'carrier',
    organisationId: company.cpsCarrierId,
  }

  await identify(ldContext)
  console.info(
    '[orcas-mobile-shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info(
    '[orcas-mobile-shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
