import {
  IModulesConfig,
  IOrcasMobileSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'
import { ENV } from '@/common/config'

import { FederatedModule } from '@sennder/federated-module-loader'
import { logger } from '@/services/logger/loggers'

export const getFederatedConfig = (component: IModulesConfig) => {
  const NAMESPACE = component.component.replace(/[^a-zA-Z]/g, '')
  const REMOTE = `./${NAMESPACE}App`

  return new FederatedModule<IOrcasMobileSharedData>({
    devPort: parseInt(component.devPort.toString()),
    environment: ENV,
    moduleFederationPluginName: NAMESPACE,
    npmName: component.component,
    mountExposedName: REMOTE,
    cacheType: 'latest-release',
    logger,
  })
}
