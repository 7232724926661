import {
  Carrier,
  CarrierVettingStatus,
} from '@sennder/carrier-profile-public-axios-client'

export const getMarketplaceAccess = (carrier: Carrier): boolean => {
  return (
    !carrier?.is_denylisted &&
    carrier?.vetting_status === CarrierVettingStatus.Completed
  )
}

export const getOnboardingFormAccess = (carrier: Carrier): boolean => {
  // There is no need to check the source as only direct signup users are created before vetting
  return (
    !carrier?.is_denylisted &&
    carrier?.vetting_status !== CarrierVettingStatus.Completed
  )
}
