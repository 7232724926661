import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import { cusAuthInstance } from '@/services/cusAuth'
import errorsHandler from '@/services/errors-handler'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
  calledByUser?: boolean
}

const logoutWithAuth0 = async () => {
  const auth0Instance = getAuth0Instance()
  try {
    await auth0Instance.logout({ returnTo: window.location.origin })
  } catch (error) {
    errorsHandler(
      error,
      '[orcas-mobile-shell - logoutActions]: Cant logout with Auth0 token: '
    )
  }
}

export const logout = async (
  options: BaseLogoutOptions = { callBackend: false, calledByUser: false }
) => {
  const auth0Instance = getAuth0Instance()
  if (!auth0Instance) {
    throw Error(
      '[orcas-mobile-shell - logoutActions]: Auth0 was not initialized'
    )
  }
  const isAuthenticatedSPA = await auth0Instance.isAuthenticated()
  const isAuthenticatedRO = await cusAuthInstance.hasTokenRequestKey()

  const loginRoute = isAuthenticatedSPA ? '/login' : '/auth/login-v2'

  if (isAuthenticatedSPA) {
    await logoutWithAuth0()
  } else if (isAuthenticatedRO) {
    await cusAuthInstance.logout()
  }
  clearState()
  localStorage.removeItem('authenticationStatus')
  logger.info(
    '[orcas-mobile-shell - logoutActions]: Logout successful',
    options
  )
  logger.unsetUserData()
  // TODO: this is a temporary solution. Tenant should not be stored in state
  window.location.replace(loginRoute)

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
