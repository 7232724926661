import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { AUTH0_USE_TOKEN_POPUP } from '@/common/config'
import { cusAuthInstance } from '@/services/cusAuth'

export async function getAuth0Token(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  let haveToUsePopup = usePopup

  if (options?.audience) {
    haveToUsePopup = AUTH0_USE_TOKEN_POPUP
  }

  return getAuth0TokenInternal(options, haveToUsePopup, throwException)
}

export async function getAuth0Header(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const token = await getAuth0Token(options, usePopup, throwException)

  return token ? `Bearer ${token}` : null
}

async function getAuth0TokenInternal(
  options?: GetTokenSilentlyOptions,
  usePopup = false,
  throwException = false
): Promise<string | null> {
  const auth0Instance = getAuth0Instance()

  const isAuthenticatedSPA = await auth0Instance?.isAuthenticated()

  if (isAuthenticatedSPA) {
    return await auth0Instance.getToken(options, usePopup, throwException)
  }

  const isAuthenticatedByCUS = await cusAuthInstance.hasTokenRequestKey()

  if (isAuthenticatedByCUS) {
    return await cusAuthInstance.getToken(options)
  }

  return null
}

export async function hasBeenAuthenticated(): Promise<boolean> {
  const auth0Instance = getAuth0Instance()

  const isAuthenticatedSPA = await auth0Instance?.isAuthenticated()

  if (isAuthenticatedSPA) {
    return true
  }

  return await cusAuthInstance.hasTokens()
}
