import { RouteRecordRaw, Router } from 'vue-router'

import {
  IModulesConfig,
  IOrcasMobileSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'
import { createMfWrapper, MicroFrontendLogger } from '@sennder/shell-utilities'

import { moduleConfiguration } from '@/modules-configuration'
import {
  Middleware,
  isAuthenticated,
  isNotAuthenticated,
  isRootAuthenticated,
  projectMiddlewares,
} from './middlewares/middlewares'
import { registerMiddlewares } from './middlewares/middlewares-registrator'
import { analytics, AppAnalyticsProvider } from '@/services/analyticsProvider'
import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import router from '@/router'
import { getFederatedConfig } from '@/services/getFederatedConfig'
import { getStateCallbacks, getStateProviders, getStoreData } from '@/store'
import { loggerInstance } from '@/services/logger'

const createComponent = (module: IModulesConfig) => {
  const data = getStoreData()

  return createMfWrapper<IOrcasMobileSharedData>({
    allowEnvironmentOverrides: isLocalEnv(),
    getData: async () => ({
      data,
      callbacks: getStateCallbacks(),
      providers: getStateProviders(),
    }),
    router,
    hooks: {
      failure: logger.error,
    },
    providers: {
      getAnalytics: (ctx) => new AppAnalyticsProvider(ctx),
      getLogger: (ctx) => new MicroFrontendLogger(ctx, () => loggerInstance),
    },
    mf: {
      id: module.component,
      fml: getFederatedConfig(module),
      context: {
        analytics: module.analyticsContext,
        logger: module.logContext,
      },
    },
  })
}

const addModuleRoute = (module: IModulesConfig, router: Router) => {
  const component = () => createComponent(module)

  const middlewares: Middleware[] = module.middlewares
    ? module.middlewares
        .filter(
          (middlewareName) =>
            !!projectMiddlewares[
              middlewareName as keyof typeof projectMiddlewares
            ]
        )
        .map((middlewareName) => {
          return projectMiddlewares[
            middlewareName as keyof typeof projectMiddlewares
          ]
        })
    : []

  router.addRoute({
    path: module.route,
    name: module.name,
    component,
    meta: {
      layout: module.layout,
      middlewares,
      moduleComponentName: module.component,
      analyticsName: module.analyticsContext.module,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: module.name,
        meta: {
          middlewares,
          moduleComponentName: module.component,
          analyticsName: module.analyticsContext.module,
        },
        component,
      },
    ],
  })
}

export const registerRoutesAndMiddlewares = (router: Router) => {
  router.addRoute({
    path: '/login',
    name: 'Login',
    redirect: '/not-authorized',
  })
  router.addRoute({
    path: '/not-authorized',
    name: 'NotAuthorized',
    meta: { middlewares: [isNotAuthenticated] },
    component: () => import('../components/pages/NotAuthorized.vue'),
  })
  router.addRoute({
    path: '/',
    name: 'Redirect',
    meta: { middlewares: [isRootAuthenticated] },
  } as RouteRecordRaw)
  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/pages/NotFound.vue'),
    meta: {
      middlewares: [isAuthenticated],
    },
  })

  moduleConfiguration.forEach((module) => addModuleRoute(module, router))

  registerMiddlewares(router)

  const PAGE_VIEW_EXCLUDE_COMPONENTS = ['marketplace']

  router.beforeResolve((to) => {
    const { meta } = to

    // TODO: remove automatic trackPage() calls when all mFs track their own pages
    const component = meta.moduleComponentName
    if (
      (!component || !PAGE_VIEW_EXCLUDE_COMPONENTS.includes(component)) &&
      meta.analyticsName
    ) {
      analytics.trackPage(meta.analyticsName, { module: meta.analyticsName })
    }

    logger.debug(
      `[orcas-mobile-shell - router]: Navigating to [${to.fullPath}]`,
      {}
    )
  })
}
